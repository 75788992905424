import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Modal, Row, Select, Table, Form, Tooltip, Flex, Popover } from 'antd';
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    CheckOutlined, 
    WarningOutlined,
    DollarOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    SwapOutlined,
    TeamOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps, ValuesForm } from './types';
import { Transaction } from '../../../Services/useGetMovements';
import { useGetAllClients } from '../../../Services/useGetAllClients';
import { useGetCurrency } from '../../../Services/useGetCurrency';
import DepositExtractionForm from '../../Forms/DepositExtractionForm';
import PucrchaseSaleCurrencyForm from '../../Forms/PucrchaseSaleCurrencyForm';
import { useOperations } from '../../../Services/useOperations';
import TransfersBetweenAccounts from '../../Forms/TransfersBetweenAccounts';
import {  useAccounts } from '../../../Services/useAccounts';
import moment from "moment";
import { useGetOperationType } from '../../../Services/useGetOperationType';
import { DatePicker, Space } from 'antd';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { OperationsContext } from '../../../Contexts/UserContext/OperationsContext';
import { VariablesContext } from '../../../Contexts/UserContext/VariablesContext';
import { DataType } from '../../../Contexts/UserContext/MovementAcountContext';
import { RenderApproved } from '../../RenderApproved';
import TransfersBetweenBoxes from '../../Forms/TransfersBetweenBoxes';
import Windows from '../../Forms/Windows';
import USDT from '../../Forms/USDT';
import TransfersSuppliers from '../../Forms/TransfersSuppliers';


const OperationSections: React.FC<UsersSectionProps> = () => {

  const { data: clients } = useGetAllClients()
  const { data: currencies } = useGetCurrency()
  const { Option } = Select;
  const { getOperationAmount, deleteOperation } = useOperations()
  const { data: operation_type } = useGetOperationType()
  const { RangePicker } = DatePicker;
  const { adjustedTableHeigthScroll } = useContext(StylesContext)  
  const { setShowForms, showForms } = useContext(VariablesContext)

  const [form_deposit_extraction] = useForm()
  const [form_windows] = useForm()
  const [form_transferers] = useForm()
  const [form_buy_and_sell] = useForm()
  const [form_operations_filters] = useForm()
  const [transfers_between_cashs] = useForm()
  const [transferer_to_suppliers] = useForm()

  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [dataApi, setDataApi] = useState<Transaction[]>([])
  const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const [currencySelected, setCurrencySelected] = useState<{
    id: number,
    name: string,
    sign: string
  }>()

  const {
    dataTable, 
    setDataTable,
    mutate, 
    setMutate, 
    sectionsFilters, 
    setSectionsFilters
  } = useContext(OperationsContext)

  const mandatory = [
    {
      required: true,
      message:'Este campo es obligatorio'
    }
  ] 

  const errorCustomers = 'NO HAY CLIENTES CREADOS PARA HACER UNA BÚSQUEDA POR CLIENTE. CREE UNO EN: ADMINISTRACIÓN => CLIENTES.'
  const errorMoney = 'NO HAY MONEDAS CREADAS PARA HACER UNA BÚSQUEDA POR MONEDA. CREE UNA EN: ADMINISTRACIÓN => MOEDAS.'
  
  useEffect(()=>{
    if(dataTable.length == 0){
      handleSearch(true)      
    }
    if(sectionsFilters){
      form_operations_filters.setFieldsValue({
        // date:{
        //   to: sectionsFilters.date?.to || undefined,
        //   from: sectionsFilters.date?.from || undefined,
        // },
        operation_type: sectionsFilters.operation_type || undefined,
        customer: sectionsFilters.customer || undefined,
        currency: sectionsFilters.currency || undefined,
      })
    }
  },[])
  

  useEffect(()=>{
    if(mutate){
      handleSearch(false)  
      setMutate(false)
    }
  },[mutate])

  const rowData = async (data: Transaction[]) => {
    if (!data) return; 
    const promises = data.map(async (transaction) => {
      console.log('Balance ' + transaction.destination_account?.balance?.toString())
      return {
        id: transaction.id.toString(),
        operation_id: transaction.operation_id?.toString() || '',
        operation_type_id: transaction.type.id.toString(),
        operation_type_name: transaction.type.name,
        created_at: moment(transaction.updated_at).format('DD-MM-YYYY') || moment(transaction.created_at).format('DD-MM-YYYY  HH:mm:ss'),
        updated_at:  moment(transaction.updated_at).format('DD-MM-YYYY') || moment(transaction.updated_at).format('DD-MM-YYYY  HH:mm:ss'),    
        credit: transaction.credit?.toString() || '',
        debit: transaction.debit?.toString() || '',
        full_name: transaction.full_name || '',
        approved: transaction.approved ? 'true' : 'false',
        currency_sale: transaction.currency_sale ? 'true' : 'false',
        is_deleting_operation: transaction.is_deleting_operation ? 'true' : 'false',
        deleted: transaction.deleted ? 'true' : 'false',
        description: transaction.description || '',

        credit_number: transaction.credit?.toString() || '',
        debit_number: transaction.debit?.toString() || '',

        origin_account_id: transaction.origin_account?.id?.toString() || '',
        origin_account_customer_name: transaction.origin_account?.customer?.first_name || '',
        origin_account_customer_last_name: transaction.origin_account?.customer?.last_name || '',
        origin_account_customer_id: transaction.origin_account?.customer?.id?.toString() || '',
        origin_account_currency_name: transaction.origin_account?.currency?.name || '',
        origin_account_currency_sign: transaction.origin_account?.currency?.sign || '', 
        origin_account_currency_id: transaction.origin_account?.currency?.id?.toString() || '', 
        origin_account_balance: transaction.origin_account_balance?.toString() || '',  //balance actual          

        destination_account_id: transaction.destination_account?.id?.toString() || '',          
        destination_account_customer_name: transaction.destination_account?.customer?.first_name || '',
        destination_account_customer_last_name: transaction.destination_account?.customer?.last_name || '',
        destination_account_customer_id: transaction.destination_account?.customer?.id?.toString() || '',    
        destination_account_currency_name: transaction.destination_account?.currency?.name || '',
        destination_account_currency_sign: transaction.destination_account?.currency?.sign || '',    
        destination_account_currency_id: transaction.destination_account?.currency?.id.toString() || '',
        destination_account_balance: transaction.destination_account_balance?.toString() || '',

        
        destination_checkout_name: transaction.destination_checkout?.name || '',
        destination_checkout_id: transaction.destination_checkout?.id?.toString() || '',
        destination_checkout_balance: transaction.destination_checkout?.balance?.toString() || '',
        destination_checkout_currency_id: transaction.destination_checkout?.currency.id.toString() || '',
        destination_checkout_currency_sign: transaction.destination_checkout?.currency?.sign || '',
        destination_checkout_currency_name: transaction.destination_checkout?.currency?.name|| '',

        origin_checkout_name: transaction.origin_checkout?.name || '',
        origin_checkout_id: transaction.origin_checkout?.id?.toString() || '',
        origin_checkout_balance: transaction.origin_checkout?.balance?.toString() || '',
        origin_checkout_currency_id: transaction.origin_checkout?.currency.id.toString() || '',
        origin_checkout_currency_sign: transaction.origin_checkout?.currency?.sign || '',
        origin_checkout_currency_name: transaction.origin_checkout?.currency?.name|| '',

        balance_origin_checkout: transaction.origin_checkout_balance?.toString() || '',
        balance_destination_checkout: transaction.destination_checkout_balance?.toString() || '',
        
        customer_id: transaction.customer?.id?.toString() || '',
        customer_first_name: transaction.customer?.first_name || '',
        customer_last_name: transaction.customer?.last_name || '',        
        
        deposit_operation_approved: transaction.deposit_operation_approved ? 'true' : ' false',
        extraction_operation_approved: transaction.extraction_operation_approved ? 'true' : ' false',

        currency_quote_buy_price: transaction.currency_quote_buy_price || undefined,
        currency_quote_sell_price: transaction.currency_quote_sell_price || undefined,
        usdt_sale: transaction.usdt_sale === true ? 'True' : transaction.usdt_sale === false ? 'False' : undefined,
        usdt_quote_percentage: transaction.usdt_quote_percentage ?  transaction.usdt_quote_percentage : undefined,
        can_be_approved: transaction.can_be_approved ? 'true' : ' false',
        related_operation_id: transaction.related_operation_id?.toString() || undefined,

       secondary_currency_name: transaction.secondary_currency?.name ||undefined,
       secondary_currency_id: transaction.secondary_currency?.id.toString() || undefined,
       secondary_currency_sign: transaction.secondary_currency?.sign || undefined,

       supplier_commission: transaction.supplier_commission,
       commission: transaction.commission,
       is_sale: transaction.currency_sale ? 'true' : 'false'       
      };
    });
  
    try {
      const filterData = await Promise.all(promises);
      console.log(filterData)
      setDataTable([...filterData]);
    } catch (error) {
      console.error('Error processing row data:', error);
    }
  };

  const callbackEliminate = () =>{
      setLoadingTableData(false)
      setRowSelect(undefined)        
      setShowConfirmDelete(false)
  }

  const handleRenderer = (text: string, record: DataType) =>{
    return (
      <div className='currency_action_action_render'>
        {
          record.deleted == 'true' || 
          record.is_deleting_operation == 'true' ||
          ( record.full_name && record.operation_type_id !== '7') ||
           record.related_operation_id 
          ? <div></div>
          : <DeleteOutlined 
            onClick={()=>{
                setRowSelect(record)
                setShowConfirmDelete(true)
            }}
          style={{color: '#37bde0'}} />           
        }            
      </div>  
    )      
  }
    
  const ModalConfirmDelete = React.memo( () => {
    return(
        <Modal
            className='movements_section_icon'
            title="Eliminar"
            open={showConfirmDelete}
            onOk={async()=>{
                if(rowSelect && rowSelect.id){
                  const data = await deleteOperation(callbackEliminate, +rowSelect.id)
                  if(data){
                    handleSearch(false)
                  }
                }}}
            onCancel={()=>setShowConfirmDelete(false)}
            okText="Eliminar"
            cancelText="Cancelar"
        >
            <p>¿Estás seguro de que desea eliminar operación N° {rowSelect?.operation_id} ?</p>
        </Modal>
    )
  })

  const filters = (
      type: 'n°' 
      | 'operation_type_name' | 'approved' | 'deleted' | 'is_deleting_operation' | 'debit' 
      | 'credit'| 'destination_account_id'| 'origin_account_id' | 'destination_checkout_name' | 'origin_checkout_name'
      | 'full_name'
  ) => {
    let textAndValue: { text: string, value: string }[] = [];
    let uniqueValues = new Set();

    dataApi?.forEach((item) => {
        let code = ''
        let value = ''
        switch(type){
            case 'n°':
                code = item.id.toString()
                value =  item.id.toString()
                break;
            case 'operation_type_name':
                code = item.type.name;
                value = item.type.name;
                break;
            case 'approved':
                code = item.approved ? 'Aprobado' : 'No aprobado';
                value = item.approved ? 'true' : 'false';
                break; 
            case 'deleted':
                code = item.deleted ? 'Eliminado' : 'No eliminado';
                value = item.deleted ? 'true' : 'false';
                break;    
            case 'is_deleting_operation':
                code = item.is_deleting_operation ? 'Nota de crédito' : 'No NC';
                value = item.is_deleting_operation ? 'true' : 'false';
                break;  
            case 'debit':
              code = item.debit.toString();
              value = item.debit.toString();
              break;  
            case 'credit':
              code = item.credit.toString();
              value = item.credit.toString();
              break;                 
            case 'full_name':
                code = item.full_name;
                value = item.full_name;
                break; 
            case 'destination_account_id':
              const firstName = item.destination_account?.customer?.first_name;
              const lastName = item.destination_account?.customer?.last_name;
              const currencySign = item.destination_account?.currency?.sign;
              const destinationAccountId = item.destination_account?.id?.toString();
          
              if (firstName && lastName && currencySign && destinationAccountId) {
                  code = `${firstName} ${lastName} ${currencySign}`;
                  value = destinationAccountId;
              }
              break;  
            case 'origin_account_id':
              const firstName_1 = item.origin_account?.customer?.first_name;
              const lastName_1 = item.origin_account?.customer?.last_name;
              const currencySign_1 = item.origin_account?.currency?.sign;
              const oroginAccountId = item.origin_account?.id?.toString();
          
              if (firstName_1 && lastName_1 && currencySign_1 && oroginAccountId) {
                  code = `${firstName_1} ${lastName_1} ${currencySign_1}`;
                  value = oroginAccountId;
              }
              break;   
            case 'destination_checkout_name':
              const name = item.destination_checkout?.name           
              if (name) {
                  code = `${name}`;
                  value = item.destination_checkout.id.toString();
              }
              break;  
            case 'origin_checkout_name':
              const name_destination = item.destination_checkout?.name           
              if (name_destination) {
                  code = `${name_destination}`;
                  value = item.destination_checkout.id.toString();
              }
              break;                 
            default:
                code ='';
                value = ''
                break               
        }
       
        if (code && !uniqueValues.has(code)) {
            uniqueValues.add(code);
            textAndValue.push({
                text: code,
                value: value
            });
        }

    });

    textAndValue.sort((a, b) => {
      if (!isNaN(Number(a.text)) && !isNaN(Number(b.text))) {
          return Number(a.text) - Number(b.text); // Para números
      } else {
          return a.text.localeCompare(b.text); // Para strings
      }
    });
    return textAndValue;
  }

  const formatted = (value: string)=>{

    const number = parseFloat(value);

    let data =  new Intl.NumberFormat('es-AR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

    return  data
  }


  const RenderOperationType = React.memo((value: { text: any; record: DataType }) => {
    let txt: React.ReactNode = ''; // Cambiado a React.ReactNode para admitir JSX
    let colorTxt = 'white';
  
    switch (value.record.operation_type_id) {
      case '1':
        txt = 'Depósito'; // Cuenta corriente
        colorTxt = '#37bde0';
        break;
      case '2':
        txt = 'Extracción'; // Cuenta corriente
        colorTxt = '#37bde0';
        break;
      case '3':
        txt = (
          <>
            Transferencia <PlusCircleOutlined /> {/* Depósito bancario */}
          </>
        );
        colorTxt = '#00fff9';
        break;
      case '4':
        txt = (
          <>
            Transferencia <MinusCircleOutlined /> {/* Extracción bancaria */}
          </>
        );
        colorTxt = '#00fff9';
        break;
      case '5':
        txt = (
          <>
            Movimiento <SwapOutlined /> {/* Transferencia entre cajas */}
          </>
        );
         colorTxt = '#37bde0';
        break;
      case '6':
        txt = (
          <>
            Movimiento <TeamOutlined /> {/* Transferencia entre cuentas */}
          </>
        );
        colorTxt = '#dfdf16';
        break;
      case '7':
        txt = value.record.is_sale === 'true' ? 'Venta' : 'Compra';
        colorTxt = '#ff00c0';
        break;
      case '8':
        txt = 'Comisión';
        colorTxt = 'gray';
        break;
      case '9':
        txt =
          value.record.is_sale === 'true' ? 'Venta USDT' : 'Compra USDT';
        colorTxt = '#ff00c0';
        break;
      case '10':
        txt = (
          <>
            T. Proveedor <PlusCircleOutlined /> {/* Depósito por transferencia a proveedor */}
          </>
        );
        colorTxt = 'yellow'; // '#9600ff';
        break;
      case '11':
        txt = (
          <>
            T. Proveedor <MinusCircleOutlined /> {/* Extracción por transferencia a proveedor*/}
          </>
        );
        colorTxt = 'yellow';
        break;
      case '12':
        txt = 'Comisión';
        colorTxt = 'gray';
        break;
      default:
        txt = '';
        break;
    }
  
    return <Tooltip title={value.record.operation_type_name}>
        <span style={{ color: colorTxt, fontWeight: 700 }}>{txt}</span>
      </Tooltip> // Renderiza el contenido con el color correspondiente
  });
  
  const columns: ColumnsType<DataType> = [
    {
      title: 'N° ',
      dataIndex: 'operation_id',
      width: 60,
      key: '1',
      fixed: 'left',
      className:'cursor-pointer ',  
      filters: filters('n°'),
      onFilter: (value, record) => typeof value === 'string' && record.id == value,
      filterSearch: true, 
    },
    {
      title: 'Operación',
      dataIndex: 'operation_type_name',
      width: 130,
      key: '2',
      className: 'cursor-pointer',
      filters: filters('operation_type_name'),
      onFilter: (value, record) => typeof value === 'string' && record.operation_type_name == value,
      render: (text, record)=>{return<RenderOperationType text={text} record={record}/>},
      fixed: 'left',
    },
    {
      title: 'Fecha creación',
      dataIndex: 'created_at',
      width: 130,
      key: '3',
      className:'cursor-pointer', 
      filters: filters('n°'),
      onFilter: (value, record) => typeof value === 'string' && record.id == value,
      filterSearch: true, 
      
    },
    {
      title: 'Fecha aprobación',
      dataIndex: 'updated_at',
      width: 140,
      key: '4',
      className:'cursor-pointer', 
    },
 
 
 
   
    {
      title: 'Ventanilla',
      dataIndex: 'full_name',
      ellipsis: true,
      key: '5',
      width: 150,
      className:'cursor-pointer cell_window',
      filters: filters('full_name'),
      onFilter: (value, record) => typeof value === 'string' && record.credit == value,
      filterSearch: true,
    },

    {
      title: <DollarOutlined />,
      dataIndex: 'destination_account_currency_sign',
      key: '6',
      className:'cursor-pointer  cell_credit text-right cell_credito',  
      ellipsis: true,
      width: 60,   
    },
    {
      title: 'Crédito',
      dataIndex: 'credit',
      key: '7',      
      width: 110,   
      className:'cursor-pointer cell_credit text-right cell_credito',
      filters: filters('credit'),
      onFilter: (value, record) => typeof value === 'string' && record.credit == value,
      filterSearch: true,
      render:(text)=><div>{text !== '0.00' ? formatted(text) : ''}</div>,
    },  

    {
      title: 'Cuenta destino', //Cuenta destino
      dataIndex: 'destination_account_id',
      render: (text, record)=><div>
        { (record.destination_account_customer_id && record.destination_account_customer_id !== '0') &&
            record.destination_account_customer_name + ' ' + record.destination_account_customer_last_name 
        }
        </div>,
      key: '8',
      className:'cursor-pointer  cell_color_destination_account cell_border_left cell_credit',  
      ellipsis: true,
      width: 150,   
      filters: filters('destination_account_id'),
      onFilter: (value, record) => typeof value === 'string' && record.destination_account_id == value,
      filterSearch: true,
    },
  
    // {
    //   title: 'Balance CD',
    //   dataIndex: 'destination_account_balance',     
    //   key: '13',
    //   width: 100,
    //   className:'cursor-pointer  cell_color_destination_account cell_border_left cell_credit',  
    //   ellipsis: true   
    // },


    {
      title: 'Caja destino',
      dataIndex: 'destination_checkout_name',
      key: '9',      
      width: 120,  
      render: (text, record)=> 
        record.operation_type_id !== '7' &&
        record.operation_type_id !== '9' && <div>{text} </div>,
      className:'cursor-pointer cell_color_destination_account cell_border_left cell_credit',
      filters: filters('destination_checkout_name'),
      onFilter: (value, record) => typeof value === 'string' && record.destination_checkout_id == value,
      filterSearch: true,      
    },
    {
      title: 'Saldo',
      dataIndex: 'balance_destination_checkout',
      key: '10',   
      width: 110,
      className:'cursor-pointer cell_color_destination_account cell_border_left cell_credit',
      render: (text, record)=> 
        record.operation_type_id !== '7' &&
        record.operation_type_id !== '9' && <div>{text && text !== '' && formatted(text)} </div>,
    },
    {
      title: 'Saldo actual',
      dataIndex: 'destination_checkout_balance',
      key: '11',      
      width: 100,   
      className:'cursor-pointer cell_color_destination_account cell_border_left cell_credit',
      render: (text, record)=> 
        record.operation_type_id !== '7' &&
        record.operation_type_id !== '9' && <div>{text && text !== '' && formatted(text)} </div>,
    }, 




    {
      title: <DollarOutlined />,
      dataIndex: 'origin_account_currency_sign',
      key: '12',
      className:'cursor-pointer cell_border_left cell_debit text-right cell_debito',  
      ellipsis: true,
      width: 60,   
    },

    {
      title: 'Débito',
      dataIndex: 'debit',
      key: '13',
      width: 110,
      className:'cursor-pointer cell_debit text-right cell_debito',
      filters: filters('debit'),
      onFilter: (value, record) => typeof value === 'string' && record.debit == value,
      filterSearch: true, 
      render:(text)=><div>{text !== '0.00' ? formatted(text) : ''}</div>,
    },    

  
    {
      title: 'Cuenta origen',
      dataIndex: 'origin_account_id',
      render: (text, record)=><div>{
         (record.origin_account_customer_id && record.origin_account_customer_id !== '0' ) &&
         record.origin_account_customer_name + ' ' + record.origin_account_customer_last_name 
        }</div>,
      key: '14',
      className:'cursor-pointer cell_color_origin_account cell_border_left cell_debit',  
      ellipsis: true,  
      width: 170, 
      filters: filters('origin_account_id'),
      onFilter: (value, record) => typeof value === 'string' && record.origin_account_id == value,
      filterSearch: true,
    },    
  
    // {
    //   title: 'Balance CO',
    //   dataIndex: 'origin_account_balance',     
    //   key: '16',
    //   className:'cursor-pointer cell_color_origin_account cell_border_left cell_debit',  
    //   ellipsis: true,
    //   width: 100,         
    // },  





    {
      title: 'Caja origen',
      dataIndex: 'origin_checkout_name',
      key: '15',      
      width: 120,   
      className:'cursor-pointer cell_color_origin_account cell_border_left cell_debit',
      render: (text, record)=> 
        record.operation_type_id !== '7' &&
        record.operation_type_id !== '9' && <div>{text} </div>,
      filters: filters('origin_checkout_name'),
      onFilter: (value, record) => typeof value === 'string' && record.origin_checkout_id == value,
      filterSearch: true,
    }, 
    {
      title: 'Saldo',
      dataIndex: 'balance_origin_checkout',
      key: '16',   
      width: 110,
      className:'cursor-pointer cell_color_origin_account cell_border_left cell_debit',
      render: (text, record)=> 
        record.operation_type_id !== '7' &&
        record.operation_type_id !== '9' && <div>{text && text !== '' && formatted(text)} </div>,
    },
    {
      title: 'Saldo Actual',
      dataIndex: 'origin_checkout_balance',
      key: '17',      
      width: 105,   
      className:'cursor-pointer cell_color_origin_account cell_border_left cell_debit',
      render: (text, record)=> 
        record.operation_type_id !== '7' &&
        record.operation_type_id !== '9' && <div>{text && text !== '' && formatted(text)} </div>,
    },    
    {
      title: 'Descripción',
      dataIndex: 'description',
      className: 'cursor-pointer',
      width: 310,
      key: '18',
    },       
    {
      title: 'Eliminado',
      dataIndex: 'deleted',
      key: '19',
      render: (text)=><div>{text == 'true' 
        ? <CheckOutlined style={{color: 'red'}} />
        : ''  }</div>, 
      width: 100,
      className:'cursor-pointer ',   
      filters: filters('deleted'),
      onFilter: (value, record) => typeof value === 'string' && record.deleted == value,           
    },   
    {
      title: 'NC',
      dataIndex: 'is_deleting_operation',
      key: '20',
      render: (text)=><div>{text == 'true' ? <CheckOutlined style={{color: 'red'}} /> : ''}</div>, 
      width: 70,
      className:'cursor-pointer',    
      filters: filters('is_deleting_operation'),
      onFilter: (value, record) => typeof value === 'string' && record.is_deleting_operation == value,     
    }, 
    {
      title: 'Aprobado',
      dataIndex: 'approved',
      key: '21',
      render: (text: string, record: DataType)=> 
        <RenderApproved 
          text={text} 
          record={record} 
          setDataTable={setDataTable} 
          dataTable={dataTable} 
          handleSearch ={handleSearch}
        />,
      width: 100,
      className:'cursor-pointer', 
      filters: filters('approved'),
      onFilter: (value, record) => typeof value === 'string' && record.approved == value,      
      fixed: 'right'
    },     
    {
      title: '' ,
      dataIndex: 'id',
      key: '22',        
      render:  handleRenderer,
      width:55,     
      fixed: 'right' 
    }, 
  ];

  
  const onClickRow = (record: DataType) =>{         
    if(record &&  record.approved == 'false'){
      setRowSelect(record);
    }  
  }   
  
    
    
  const handleSearch = async(loading: boolean) =>{
      if(loading){
        setLoadingTableData(true)
      }      
      let formData:{
        date: {$d: string}[],
        operation_type: any,
        customer: ValuesForm, 
        currency: ValuesForm        
      } = form_operations_filters.getFieldsValue()

      let start_date = formData.date && formData.date[0] ? moment(formData.date[0].$d).format('YYYY-MM-DD') : undefined
      let end_date = formData.date && formData.date[1] ? moment(formData.date[1].$d).format('YYYY-MM-DD') : undefined
      let currency =  formData.currency || undefined  
      let customer =  formData.customer || undefined
      let operation_type = formData.operation_type || undefined 
      
      let values ={
        customer_id: customer && customer.key,
        currency_id: currency && currency.key, 
        checkout_id: undefined,   
        start_date: start_date,
        end_date: end_date,
        operation_type: operation_type && operation_type.key
      }

     // checkAccountBalance()
      const data = await getOperationAmount(
        callbackEliminate,
        values
      )  
      if(data){
        rowData(data)
        setDataApi(data)
        setSectionsFilters({
          date: {
            to: formData.date && formData.date[0] || undefined,
            from: formData.date && formData.date[1] || undefined
          },
          currency:  formData.currency || undefined,          
          customer: formData.customer || undefined,
          operation_type: formData.operation_type || undefined,          
        })
      }   
  }

  const onChangeCurrency =React.useCallback( (e: {label: string, key: number}, value: any) =>{
    if(e){
      setCurrencySelected({
        name: e.label,
        id: +e.key,
        sign: value.sign
      })    
    }    
  },[])

  return( 
    <>
      <ModalConfirmDelete/>
      { showForms.usdt &&
        <USDT
          form_buy_and_sell={form_buy_and_sell}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      } 
      { showForms.windows &&
        <Windows
          form_buy_and_sell={form_buy_and_sell}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      } 
      { showForms.deposit_extraction &&
        <DepositExtractionForm
          form={form_deposit_extraction}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      }
      { showForms.transferer && 
        <TransfersBetweenAccounts
          form={form_transferers}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      }    
      { showForms.buy_and_sell &&
        <PucrchaseSaleCurrencyForm
          form_buy_and_sell={form_buy_and_sell}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      }   
      { showForms.transfers_between_cashs && 
        <TransfersBetweenBoxes
          form={transfers_between_cashs}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      } 
      {
        showForms.transferer_to_suppliers &&
          <TransfersSuppliers
            form={transferer_to_suppliers}
            rowSelect={rowSelect}
            setRowSelect={setRowSelect}
          />
      }   
      <div className='movements_section movements_section_container'>  
          <Row
            className='global_title_section'  
          >
            <Col
              style={{
                display: 'flex',
                alignItems: ' center',
              }}
            >
              <h3> <DollarOutlined style={{fontSize: 18, marginRight: '10px', color: '#37bde0'}}/>OPERACIONES</h3> 
            </Col>
          </Row>                                          
          <Row 
            className='global_button_box'
            style={{
              display:'flex',
              justifyContent: 'space-between',
              alignItems:'center'
            }}
          >              
            <Col >
              <Form
                layout='inline'
                onFinish={()=>handleSearch(true)}
                id='form_operations_filters' 
                form={form_operations_filters}
              >
                <Form.Item
                    name='date'
                >
                  <RangePicker 
                    style={{width: 240}}
                    placeholder={['Desde', 'Hasta']} 
                  />
                </Form.Item>
                <Form.Item
                    name='operation_type'
                >
                  <Select                           
                    labelInValue  
                    loading={!operation_type }
                    placeholder={!operation_type ? 'Cargando campos...' : 'Operación'}      
                    style={{minWidth: '100px'}}    
                    showSearch 
                    allowClear                                                 
                  >
                      {operation_type && operation_type.map((item) => (
                          <Option value={item.name} key={item.id} >
                            {item.name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item>
                
                <Form.Item
                    name='customer'
                >
                  <Select                           
                    labelInValue  
                    loading={!clients}
                    placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                    style={{minWidth: '100px'}}   
                    showSearch  
                    allowClear                                                 
                  >
                      {clients && clients.map((item) => (
                          <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                            {item.first_name + ' ' + item.last_name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item>
                <Form.Item
                    name='currency'
                >
                  <Select                           
                      labelInValue  
                      loading={!currencies}
                      placeholder={!currencies ? 'Cargando monedas...' : 'Moneda'}                       
                      style={{minWidth: '100px'}}
                      onChange={(e, value) => onChangeCurrency(e, value)}
                      showSearch
                      allowClear 
                  >
                      {currencies && currencies && currencies.map((item) => (
                          <Option value={item.name} key={item.id} sign={item.sign} >
                            {item.name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                  type='primary'
                  className='movements_section_btn'
                  htmlType='submit'
                  loading={loadingBtn}
                  >
                    Buscar
                  </Button>
                </Form.Item>
                <Tooltip
                  title= 'Seleccione uno filtro o varios filtros para hacer una búsqueda.'
                  className='cursor-pointer'
                >
                  <ExclamationCircleOutlined style={{color: 'orange'}}/>
                </Tooltip>
              </Form>                               
            </Col>  
                          
          </Row>
          {((clients && clients.length === 0) || (currencies && currencies.length === 0)) &&
            <Row
              style={{ 
                paddingLeft: '30px',
                paddingBottom: '10px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {clients && clients.length === 0 && (
                <span style={{color: '#ff6161', fontFamily: 'monospace'}}>
                  <WarningOutlined 
                    style={{marginRight: '10px', marginTop: '10px', color: '#71e3ff'}} 
                  /> 
                  {errorCustomers}
                </span>
              )}
              {
                currencies && currencies.length === 0 && (
                <span style={{color: '#ff6161', fontFamily: 'monospace'}}>
                  <WarningOutlined 
                    style={{marginRight: '10px', marginTop: '10px', color: '#71e3ff'}} 
                  /> 
                  {errorMoney}
                </span>
              )}
            </Row>
          }
          <Row 
            style={{
                display:'flex',
                justifyContent: 'center',
                width: '100%',
                height: '85%',
                padding: '0px 30px',                        
                border: '1px solid   #645fca6c'
            }}
          >
            { loadingTableData && <SpinerHorizontal/>}
            { !loadingTableData && dataTable
                ?
                  <Table 
                    style={{
                        width: '100%',
                        marginTop: '1%'
                    }}                    
                    columns={columns} 
                    dataSource={dataTable}  
                    scroll={{ y: adjustedTableHeigthScroll, x: 800 }}                                   
                    pagination={{
                        pageSize: 50,
                        showSizeChanger: false,                                      
                    }}
                    rowClassName={(record)=>{  return record.deleted === 'true' || record.is_deleting_operation === 'true' ? 'cell_deleted' : ''}}
                    onRow={(record, ) => ({
                      onClick: (event) => {
                        // Verifica condiciones principales y selecciona celdas de "Aprobado" y "Eliminar" una sola vez
                        const isApprovable = record.approved === 'false' && record.can_be_approved === 'true' && record.deleted === 'false' && record.is_deleting_operation === 'false';
                        const noRelatedOperation = !record.related_operation_id || record.related_operation_id === 'false';
                        
                        if (isApprovable && noRelatedOperation) {
                          const approvedCell = event.currentTarget.querySelector('td:nth-child(21)');
                          const deleteCell = event.currentTarget.querySelector('td:nth-child(22)');
                      
                          // Verifica si el clic ocurrió en las celdas "Aprobado" o "Eliminar"
                          if ((approvedCell && approvedCell.contains(event.target)) || (deleteCell && deleteCell.contains(event.target))) {
                            return;
                          }
                      
                          // Llamada al manejador de clic
                          onClickRow(record);
                      
                          // Variables comunes para los estados de formulario
                          const isAccountValid = record.origin_account_customer_id !== '0' && record.destination_account_customer_id !== '0';
                          const isRelated = isAccountValid && !record.related_operation_id;
                      
                          // Define el estado del formulario
                          const formStates = {
                            deposit_extraction: ['1', '2', '3', '4'].includes(record.operation_type_id) && isRelated,
                            transfers_between_cashs: record.operation_type_id === '5' && isAccountValid,
                            transferer: record.operation_type_id === '6' && isAccountValid,
                            buy_and_sell: record.operation_type_id === '7' && isRelated && record.origin_account_customer_id !== '0',
                            windows: record.operation_type_id === '7' && record.origin_account_customer_id === '0',
                            usdt: record.operation_type_id === '9' && isAccountValid,
                            transferer_to_suppliers: ['10', '11'].includes(record.operation_type_id) && isAccountValid, 
                          };
                      
                          setShowForms(formStates);
                        }
                      }
                    })}
                  /> 
                : 
                 <>
                 </>
            }                             
          </Row>             
      </div>
    </>
  )
};

export default OperationSections;

